export default {
  computed: {
    priceFormatter() {
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        currencyDisplay: 'narrowSymbol',
      })
    }
  }
}